const Experiences = (($) => {
    /**
    * Constants
    * ====================================================
    */

    const NAME               = 'Experiences'
    const DATA_KEY           = 'lte.experiences'
    const EVENT_KEY          = `.${DATA_KEY}`
    const JQUERY_NO_CONFLICT = $.fn[NAME]

    const Event = {}

    const Selector = {
        EXPERIENCE_SECTIONS_WRAPPER : '.js-experience-sections',
        EXPERIENCE_SECTION_CARD : '.js-experience-section-card',
        EXPERIENCE_SECTION_CARD_BODY : '.js-experience-section-card .card-body',
        EXPERIENCE_SECTION_POSITION_INPUT : '.js-section-position',
        EXPERIENCE_SPHERES_WRAPPER : '.js-experience-sphere-wrapper',
        EXPERIENCE_SPHERE_CARD : '.js-experience-sphere',
        EXPERIENCE_SPHERE_POSITION_INPUT : '.js-experience-sphere-position',
    }

    const ClassName = {}

    const Default = {}

    /**
    * Class Definition
    * ====================================================
    */

    class Experiences {
        constructor(element, config) {
            this._config  = config
            this._element = element

            this._init()
        }

        _init() {
            this._setupListeners()
        }

        _setupListeners() {
            var that = this

            // document is ready
            $(document).ready(function() {
                that.setSectionsPosition()
                that.setSpheresPosition()
            })

            // update positions when a form is added
            $(document).on('sf-add-form', 'body', function(e) {
                that.setSectionsPosition()
                that.setSpheresPosition()
            })

            // sort sections
            $(Selector.EXPERIENCE_SECTIONS_WRAPPER).sortable({
                items: Selector.EXPERIENCE_SECTION_CARD,
                cancel: Selector.EXPERIENCE_SECTION_CARD_BODY,
                axis: 'y',
                containment: 'parent',
                stop: function(e, ui) {
                    that.setSectionsPosition()
                },
            })

            // sort spheres
            $(Selector.EXPERIENCE_SPHERES_WRAPPER).sortable({
                items: Selector.EXPERIENCE_SPHERE_CARD,
                axis: 'y',
                stop: function(e, ui) {
                    that.setSpheresPosition()
                },
            })
        }

        setSectionsPosition() {
            $.each($(Selector.EXPERIENCE_SECTION_CARD), function() {
                const position = $(this).index() + 1
                $(this).find(Selector.EXPERIENCE_SECTION_POSITION_INPUT).val(position)
            })
        }

        setSpheresPosition() {
            $.each($(Selector.EXPERIENCE_SPHERE_CARD), function() {
                const position = $(this).index() + 1
                $(this).find(Selector.EXPERIENCE_SPHERE_POSITION_INPUT).val(position)
            })
        }

        static _jQueryInterface() {
            let data = new Experiences()
        }
    }

    $(window).on('load', () => {
        Experiences._jQueryInterface.call()
    })

    /**
    * jQuery API
    * ====================================================
    */

    $.fn[NAME] = Experiences._jQueryInterface
    $.fn[NAME].Constructor = Experiences
    $.fn[NAME].noConflict = function () {
        $.fn[NAME] = JQUERY_NO_CONFLICT
        return Experiences._jQueryInterface
    }

    return Experiences
})(jQuery)

export default Experiences
