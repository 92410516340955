const Pages = (($) => {
    /**
    * Constants
    * ====================================================
    */

    const NAME               = 'Pages'
    const DATA_KEY           = 'lte.pages'
    const EVENT_KEY          = `.${DATA_KEY}`
    const JQUERY_NO_CONFLICT = $.fn[NAME]

    const Event = {}

    const Selector = {
        PAGE_SECTIONS_WRAPPER : '.js-page-sections',
        PAGE_SECTION_TYPE_SELECT : '.js-page-section-type',
        PAGE_SECTION_CARD : '.js-page-section-card',
        PAGE_SECTION_CARD_BODY : '.js-page-section-card .card-body',
        PAGE_SECTION_TITLE : '.js-page-section-title',
        PAGE_SECTION_TITLE_INPUT : '.js-page-section-title-input',
        PAGE_SECTION_POSITION_INPUT : '.js-section-position',
        PAGE_SECTIONS_PROCESSES_WRAPPER : '.js-page-section-processes',
        PAGE_SECTION_PROCESS : '.js-page-section-process',
        PAGE_SECTION_PROCESS_POSITION_INPUT : '.js-page-section-process-position',
        PAGE_SECTION_PROCESS_CANCEL_SORTABLE : '.js-cancel-sortable',
        PAGE_SECTION_STATS_WRAPPER : '.js-page-section-stats',
        PAGE_SECTION_STAT : '.js-page-section-stat',
        PAGE_SECTION_STAT_POSITION_INPUT : '.js-page-section-stat-position',
        PAGE_SECTION_CONTENT_NAV : '.js-page-section-content-nav',
        PAGE_CONTENT_ALERT : '.js-page-content-alert',
        DELETE_SECTION_BTN : '.js-delete-section-btn',
    }

    const ClassName = {}

    const Default = {}

    /**
    * Class Definition
    * ====================================================
    */

    class Pages {
        constructor(element, config) {
            this._config  = config
            this._element = element

            this._init()
        }

        _init() {
            this._setupListeners()
        }

        _setupListeners() {
            var that = this

            // document is ready
            $(document).ready(function() {
                that.setSectionsPosition()
                that.setProcessesPosition()
                that.setStatsPosition()
                that.setSectionsNav(true)
            })

            // update positions when a form is added
            $(document).on('sf-add-form', 'body', function(e) {
                that.setSectionsPosition()
                that.setProcessesPosition()
                that.setStatsPosition()
                that.setSectionsNav(false)
            })

            // update list of section content types
            $(document).on('change', Selector.PAGE_SECTION_TYPE_SELECT, function() {
                const sectionCard = $(this).parents(Selector.PAGE_SECTION_CARD)
                const sectionTypeName = $(this).find('option:selected').html()

                that.setSectionContentNav(sectionCard, sectionTypeName, true)
            })

            // update section title
            $(document).on('keyup', Selector.PAGE_SECTION_TITLE_INPUT, function() {
                $(this).parents(Selector.PAGE_SECTION_CARD).find(Selector.PAGE_SECTION_TITLE).html($(this).val())
            })

            // sort sections
            $(Selector.PAGE_SECTIONS_WRAPPER).sortable({
                items: Selector.PAGE_SECTION_CARD,
                cancel: Selector.PAGE_SECTION_CARD_BODY,
                axis: 'y',
                containment: 'parent',
                stop: function(e, ui) {
                    that.setSectionsPosition()
                },
            })

            // sort processes
            $(Selector.PAGE_SECTIONS_PROCESSES_WRAPPER).sortable({
                items: Selector.PAGE_SECTION_PROCESS,
                cancel: Selector.PAGE_SECTION_PROCESS_CANCEL_SORTABLE,
                axis: 'y',
                stop: function(e, ui) {
                    that.setProcessesPosition()
                },
            })

            // sort stats
            $(Selector.PAGE_SECTION_STATS_WRAPPER).sortable({
                items: Selector.PAGE_SECTION_STAT,
                axis: 'y',
                stop: function(e, ui) {
                    that.setStatsPosition()
                },
            })

            // delete section
            $(document).on('click', Selector.DELETE_SECTION_BTN, function(e) {
                e.preventDefault()

                $(this).parents(Selector.PAGE_SECTION_CARD).remove()
            })
        }

        setSectionsPosition() {
            $.each($(Selector.PAGE_SECTION_CARD), function() {
                const position = $(this).index() + 1
                $(this).find(Selector.PAGE_SECTION_POSITION_INPUT).val(position)
            })
        }

        setProcessesPosition() {
            $.each($(Selector.PAGE_SECTION_PROCESS), function() {
                const position = $(this).index() + 1
                $(this).find(Selector.PAGE_SECTION_PROCESS_POSITION_INPUT).val(position)
            })
        }

        setStatsPosition() {
            $.each($(Selector.PAGE_SECTION_STAT), function() {
                const position = $(this).index() + 1
                $(this).find(Selector.PAGE_SECTION_STAT_POSITION_INPUT).val(position)
            })
        }

        setSectionsNav(setActive) {
            var that = this

            // set visible content types for each existing section
            $.each($(Selector.PAGE_SECTION_CARD), function() {
                const sectionTypeName = $(this).find(Selector.PAGE_SECTION_TYPE_SELECT+' option:selected').html()

                that.setSectionContentNav($(this), sectionTypeName, setActive)
            })
        }

        setSectionContentNav(sectionCard, sectionTypeName, setActive) {
            const navLinks = sectionCard.find(Selector.PAGE_SECTION_CONTENT_NAV+' .nav-link')

            // hide all alerts
            sectionCard.find(Selector.PAGE_CONTENT_ALERT).addClass('d-none')

            // display content type alert if defined
            const contentAlertSelector = Selector.PAGE_CONTENT_ALERT+'[data-content-type="'+sectionTypeName+'"]'
            const contentAlert = sectionCard.find(contentAlertSelector)

            if (contentAlert.length > 0) {
                contentAlert.removeClass('d-none')
            }

            // first loop to hide/show content types
            $.each(navLinks, function() {
                const sectionTypes = $(this).data('section-types').split(',')

                // if selected section type is member of nav item sections
                if (sectionTypes.indexOf(sectionTypeName) > -1) {
                    $(this).parent().removeClass('d-none')

                    // hide tab content
                    sectionCard.find($(this).attr('href')).removeClass('d-none')
                } else {
                    $(this).parent().addClass('d-none')
                    $(this).removeClass('active')

                    // show tab content
                    sectionCard.find($(this).attr('href')).addClass('d-none').removeClass('active')
                }
            })

            // second loop to set active content type
            if (true === setActive) {
                let activeId = undefined
                $.each(navLinks, function() {
                    if (
                        !$(this).parent().hasClass('d-none')
                        && 'undefined' === typeof activeId
                    ) {
                        $(this).addClass('active')
                        sectionCard.find($(this).attr('href')).addClass('active')

                        activeId = $(this).attr('href')
                    }
                })
            }
        }

        static _jQueryInterface() {
            let data = new Pages()
        }
    }

    $(window).on('load', () => {
        Pages._jQueryInterface.call()
    })

    /**
    * jQuery API
    * ====================================================
    */

    $.fn[NAME] = Pages._jQueryInterface
    $.fn[NAME].Constructor = Pages
    $.fn[NAME].noConflict = function () {
        $.fn[NAME] = JQUERY_NO_CONFLICT
        return Pages._jQueryInterface
    }

    return Pages
})(jQuery)

export default Pages
