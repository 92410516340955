const Crud = (($) => {
    /**
    * Constants
    * ====================================================
    */

    const NAME               = 'Crud'
    const DATA_KEY           = 'lte.crud'
    const EVENT_KEY          = `.${DATA_KEY}`
    const JQUERY_NO_CONFLICT = $.fn[NAME]

    const Event = {}

    const Selector = {
        SUBMIT_BTN : '.js-submit-btn',
        BOOTSTRAP_SWITCH : 'input[data-bootstrap-switch]',
        SELECT_2 : '.js-select2',
        WYSIWYG_EDITOR : '.js-wysiwyg-editor',
        ADD_FORM_BTN : '.js-crud-add-form-btn',
        FILTERS_DISPLAY_BTN : '.js-display-filters-btn',
        FILTERS_SUBMIT_BTN : '.js-filters-submit-btn',
        FILTERS_WRAPPER : '.js-filters-wrapper',
    }

    const ClassName = {}

    const Default = {}

    /**
    * Class Definition
    * ====================================================
    */

    class Crud {
        constructor(element, config) {
            this._config  = config
            this._element = element

            this._init()
        }

        _init() {
            this._setupListeners()
        }

        _setupListeners() {
            var that = this

            $(document).on('click', Selector.SUBMIT_BTN, function(e) {
                e.preventDefault()

                that.submitForm($(this))
            })

            that._initCrudInputs()

            // handle add item to collection form
            $(document).on('click', Selector.ADD_FORM_BTN, function(e) {
                e.preventDefault()

                const collectionHolderClass = $(this).data('collection-holder')
                const collectionHolderContainer = $(this).data('collection-container')

                let collectionHolder = $(`.${collectionHolderClass}`)

                if ('undefined' !== typeof collectionHolderContainer) {
                    collectionHolder = $(collectionHolderContainer).find(`.${collectionHolderClass}`)
                }

                collectionHolder.data('index', collectionHolder.find(':input').length)

                that.addForm(collectionHolder)
            })

            // display filters
            $(document).on('click', Selector.FILTERS_DISPLAY_BTN, function(e) {
                e.preventDefault()

                const filtersWrapper = $(Selector.FILTERS_WRAPPER)
                if (filtersWrapper.length > 0) {
                    filtersWrapper.toggleClass('visible')
                }
            })

            // submit filters
            $(document).on('click', Selector.FILTERS_SUBMIT_BTN, function(e) {
                e.preventDefault()

                var filtersForm = $('form[name="'+$(this).data('form')+'"]')
                if (filtersForm.length > 0) {
                    filtersForm.submit()
                }
            })
        }

        _initCrudInputs() {
            $(Selector.BOOTSTRAP_SWITCH).each(function(){
                $(this).bootstrapSwitch('state', $(this).prop('checked'))
            })

            // init Select2 inputs
            $(Selector.SELECT_2).select2()

            // init wysiwyg inputs
            $(Selector.WYSIWYG_EDITOR).summernote({
                toolbar: [
                    ['style', ['bold', 'italic', 'underline', 'clear']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['insert', ['link']],
                ],
                callbacks: {
                    onPaste: function (e) {
                        var bufferText = ((e.originalEvent || e).clipboardData || window.clipboardData).getData('Text');
                        e.preventDefault();
                        document.execCommand('insertText', false, bufferText);
                    }
                }
            })

            // init custom file inputs
            bsCustomFileInput.init()
        }

        submitForm(submitBtn) {
            const formName = submitBtn.data('form-name')
            const form = $('form[name="'+formName+'"]')

            if (form.length > 0) {
                form.submit()
            }
        }

        addForm(collectionHolder) {
            const prototype = collectionHolder.data('prototype')
            const index = collectionHolder.data('index')
            const uniqIndex = Math.floor(Math.random() * 10000) + 1

            const prototypeId = collectionHolder.data('prototype-id')
            const prototypeFullName = collectionHolder.data('prototype-full-name')
            const maxInputs = collectionHolder.data('max-inputs')

            if ('undefined' !== maxInputs && (index + 1) > parseInt(maxInputs)) {
                return false
            }

            let newForm = prototype

            if ('undefined' !== typeof prototypeFullName) {
                newForm = this.replaceAll(newForm, prototypeFullName, prototypeFullName.replace('__name__', index))

                if ('undefined' !== typeof prototypeId) {
                    newForm = this.replaceAll(newForm, prototypeId, prototypeId.replace('__name__', index))
                }
            } else {
                newForm = newForm.replace(/__name__/g, index)
            }

            newForm = newForm.replace(/__section_id__/g, uniqIndex)

            collectionHolder.data('index', index + 1)
            collectionHolder.append(newForm)

            this._initCrudInputs()

            // dispatch event
            var event = jQuery.Event('sf-add-form')
            event.collectionHolder = collectionHolder
            event.index = index

            $('body').trigger(event)
        }

        replaceAll(original, searchTxt, replaceTxt) {
            return original.split(searchTxt).join(replaceTxt)
        }

        static _jQueryInterface() {
            let data = new Crud()
        }
    }

    $(window).on('load', () => {
        Crud._jQueryInterface.call()
    })

    /**
    * jQuery API
    * ====================================================
    */

    $.fn[NAME] = Crud._jQueryInterface
    $.fn[NAME].Constructor = Crud
    $.fn[NAME].noConflict = function () {
        $.fn[NAME] = JQUERY_NO_CONFLICT
        return Crud._jQueryInterface
    }

    return Crud
})(jQuery)

export default Crud
