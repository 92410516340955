const Search = (($) => {
    /**
    * Constants
    * ====================================================
    */

    const NAME               = 'Search'
    const DATA_KEY           = 'lte.search'
    const EVENT_KEY          = `.${DATA_KEY}`
    const JQUERY_NO_CONFLICT = $.fn[NAME]

    const Event = {}

    const Selector = {
        SEARCH_INPUT : '.js-search-input',
        RESULTS_WRAPPER : '.js-search-results-wrapper',
        RESET_BTN : '.js-reset-search',
    }

    const ClassName = {}

    const Default = {}

    /**
    * Class Definition
    * ====================================================
    */

    class Search {
        constructor(element, config) {
            this._config  = config
            this._element = element

            this._init()
        }

        _init() {
            this._setupListeners()
        }

        _setupListeners() {
            const that = this

            let timer = null

            $(document).on('keydown', Selector.SEARCH_INPUT, function() {
                clearTimeout(timer)
                timer = setTimeout(that.submitSearch, 800)
            })

            $(document).on('click', Selector.RESET_BTN, function(e) {
                e.preventDefault()

                $(Selector.SEARCH_INPUT).val('')
                that.submitSearch()
            })
        }

        submitSearch() {
            const searchInput = $(Selector.SEARCH_INPUT)
            const resultsWrapper = $(Selector.RESULTS_WRAPPER)
            const query = searchInput.val()

            if (query.length > 0) {
                $(Selector.RESET_BTN).addClass('visible')
            } else {
                $(Selector.RESET_BTN).removeClass('visible')
            }

            if (query.length > 2) {
                const ajaxUrl = searchInput.data('ajax-url')

                // display spinner
                resultsWrapper.html('<i class="fas fa-3x fa-sync-alt fa-spin spinner"></i>').addClass('visible')

                $.ajax(ajaxUrl, {
                    method: 'POST',
                    data: {
                        q: query,
                    },
                    success: function(htmlResponse) {
                        resultsWrapper.html(htmlResponse)
                    }
                })
            } else {
                resultsWrapper.removeClass('visible').html('')
            }
        }

        static _jQueryInterface() {
            let data = new Search()
        }
    }

    $(window).on('load', () => {
        Search._jQueryInterface.call()
    })

    /**
    * jQuery API
    * ====================================================
    */

    $.fn[NAME] = Search._jQueryInterface
    $.fn[NAME].Constructor = Search
    $.fn[NAME].noConflict = function () {
        $.fn[NAME] = JQUERY_NO_CONFLICT
        return Search._jQueryInterface
    }

    return Search
})(jQuery)

export default Search
