const Team = (($) => {
    /**
    * Constants
    * ====================================================
    */

    const NAME               = 'Team'
    const DATA_KEY           = 'lte.team'
    const EVENT_KEY          = `.${DATA_KEY}`
    const JQUERY_NO_CONFLICT = $.fn[NAME]

    const Event = {}

    const Selector = {
        TEAM_LIST : '.js-team-list',
        TEAM_ROW : '.js-team-row',
        SORT_ERROR : '.js-team-sort-error',
    }

    const ClassName = {}

    const Default = {}

    /**
    * Class Definition
    * ====================================================
    */

    class Team {
        constructor(element, config) {
            this._config  = config
            this._element = element

            this._init()
        }

        _init() {
            this._setupListeners()
        }

        _setupListeners() {
            var that = this

            // sort sections
            $(Selector.TEAM_LIST).sortable({
                items: Selector.TEAM_ROW,
                axis: 'y',
                stop: function(e, ui) {
                    const listType = $(e.target).data('list-type')

                    that.setTeamPosition(listType)
                },
            })
        }

        setTeamPosition(listType) {
            $(Selector.SORT_ERROR).addClass('d-none')

            let positions = {}
            $.each($(Selector.TEAM_ROW), function() {
                if (listType === $(this).parent().data('list-type')) {
                    positions[$(this).data('id')] = $(this).index() + 1
                }
            })

            const ajaxUrl = $(Selector.TEAM_LIST+'[data-list-type="'+listType+'"]').data('ajax-url')
            $.ajax(ajaxUrl, {
                method: 'post',
                data: {
                    positions: positions,
                    listType: listType,
                },
                success: function(json) {
                    window.location.reload()
                },
                error: function(json) {
                    $(Selector.SORT_ERROR).removeClass('d-none')
                }
            })
        }

        static _jQueryInterface() {
            let data = new Team()
        }
    }

    $(window).on('load', () => {
        Team._jQueryInterface.call()
    })

    /**
    * jQuery API
    * ====================================================
    */

    $.fn[NAME] = Team._jQueryInterface
    $.fn[NAME].Constructor = Team
    $.fn[NAME].noConflict = function () {
        $.fn[NAME] = JQUERY_NO_CONFLICT
        return Team._jQueryInterface
    }

    return Team
})(jQuery)

export default Team
