const Companies = (($) => {
    /**
    * Constants
    * ====================================================
    */

    const NAME               = 'Companies'
    const DATA_KEY           = 'lte.companies'
    const EVENT_KEY          = `.${DATA_KEY}`
    const JQUERY_NO_CONFLICT = $.fn[NAME]

    const Event = {}

    const Selector = {
        PARTNERS_LIST : '.js-partners-list',
        PARTNER_ROW : '.js-partner-row',
        SORT_ERROR : '.js-partners-sort-error',
        ADD_PARTNER_MODAL : '#add-partner-modal',
        PARTNER_FORM_SUBMIT_BTN : '.js-partners-submit',
    }

    const ClassName = {}

    const Default = {}

    /**
    * Class Definition
    * ====================================================
    */

    class Companies {
        constructor(element, config) {
            this._config  = config
            this._element = element

            this._init()
        }

        _init() {
            this._setupListeners()
        }

        _setupListeners() {
            var that = this

            // sort sections
            $(Selector.PARTNERS_LIST).sortable({
                items: Selector.PARTNER_ROW,
                axis: 'y',
                stop: function(e, ui) {
                    that.setPartnersPositions()
                },
            })

            // handle submit partner form
            $(document).on('click', Selector.PARTNER_FORM_SUBMIT_BTN, function(e) {
                e.preventDefault()

                const form = $(Selector.ADD_PARTNER_MODAL).find('form')
                if (form.length > 0) {
                    form.submit()
                }
            })
        }

        setPartnersPositions() {
            $(Selector.SORT_ERROR).addClass('d-none')

            let positions = {}
            $.each($(Selector.PARTNER_ROW), function() {
                positions[$(this).data('id')] = $(this).index() + 1
            })

            const ajaxUrl = $(Selector.PARTNERS_LIST).data('ajax-url')
            $.ajax(ajaxUrl, {
                method: 'post',
                data: {
                    positions: positions,
                },
                success: function(json) {
                    window.location.reload()
                },
                error: function(json) {
                    $(Selector.SORT_ERROR).removeClass('d-none')
                }
            })
        }

        static _jQueryInterface() {
            let data = new Companies()
        }
    }

    $(window).on('load', () => {
        Companies._jQueryInterface.call()
    })

    /**
    * jQuery API
    * ====================================================
    */

    $.fn[NAME] = Companies._jQueryInterface
    $.fn[NAME].Constructor = Companies
    $.fn[NAME].noConflict = function () {
        $.fn[NAME] = JQUERY_NO_CONFLICT
        return Companies._jQueryInterface
    }

    return Companies
})(jQuery)

export default Companies
